/**
 * App.js
 *
 * @author Dima Dodonov <dimadodonov@icloud.com>
 */

//Global Vars
const $window = $(window);
const $document = $(document);
const $html = $('html');
const $wrapper = $('.wrapper');
const $header = $('.header');
const $main = $('.main');
const $overlay = $('.js-overlay');
const $headeH = $('.header').height();

const App = {
    init() {
        // console.log('Site init');

        this.header();
        this.nav();
        this.footer();
        this.catalog();
        this.categories();
        // this.related();
        this.product();
        this.popup();
        // this.tabs();
        this.accordion();
        this.slider();
        this.fancybox();
        this.woocommerce();
        this.login();
        // this.yandexMap();
        this.Components.init();
    },

    header() {
        // $window.scroll(function () {
        //     if ($(this).scrollTop() > $headeH) {
        //         const blockSticky = 50;
        //         $('.summary entry-summary').css('padding-top', blockSticky);
        //     } else {
        //         $('.product-attr').css('padding-top', 0);
        //     }
        //     const headertop = $('.header-top').height();
        //     if ($(this).scrollTop() > headertop) {
        //         $('.header-top').addClass('hide');
        //     } else {
        //         $('.header-top').removeClass('hide');
        //     }
        // });

        $(document).on('click', '.sboxBtn', function () {
            $(this).toggleClass('is-active');
            const sBox = $(this).attr('data-sBox');

            if ($document.width() <= 820) {
                $('body').addClass('is-fixed');
            }

            $('#' + sBox).toggleClass('is-active');
            $('#' + sBox + ' .m-sbox__overlay').toggleClass('is-active');
            $('#' + sBox + ' .m-sbox__wrap').toggleClass('is-active');
        });

        $(document).on('click', '.m-sbox__close', function () {
            $('body').removeClass('is-fixed');
            $('.m-sbox').removeClass('is-active');
            $('.m-sbox__overlay').removeClass('is-active');
            $('.m-sbox__wrap').removeClass('is-active');
        });

        $(document).on('click', '.m-sbox__overlay', function () {
            $('body').removeClass('is-fixed');
            $('.m-sbox').removeClass('is-active');
            $('.m-sbox__overlay').removeClass('is-active');
            $('.m-sbox__wrap').removeClass('is-active');
        });

        function sticky() {
            const stickyOffset = $('.main').offset().top;
            const jsHeaderSearch = $('.header-search');

            $(window).scroll(function () {
                const sticky = $('.sticky'),
                    scroll = $(window).scrollTop();

                if (scroll >= stickyOffset) {
                    if ($document.width() <= 820) {
                        sticky.addClass('is-active');
                        jsHeaderSearch
                            .insertAfter($('.header__subtitle'))
                            .addClass('fixed');
                    }
                } else {
                    if ($document.width() <= 820) {
                        sticky.removeClass('is-active');
                        jsHeaderSearch
                            .insertBefore($('.header-login'))
                            .removeClass('fixed');
                    }
                }
            });
        }

        $(window).on('resize', function () {
            sticky();
        });

        sticky();
    },
    nav() {
        $('.nav li span').on('click', function () {
            if ($('.nav li').hasClass('active')) {
                $('.nav li').removeClass('active');
            }
            $(this).parents('li').addClass('active');
        });
    },
    footer() {
        function footerNavHack() {
            const jsNavLiObj = $('.jsNavLiObj');
            if ($document.width() <= 820) {
                jsNavLiObj.insertAfter($('.jsNavLiPaste'));
            } else {
                jsNavLiObj.insertBefore($('.jsNavLiDefault'));
            }
        }
        function footerNavTwoHack() {
            const jsNavTwoLiObj = $('.jsNavTwoLiObj');
            if ($document.width() <= 820) {
                jsNavTwoLiObj.insertAfter($('.jsNavTwoLiPaste')).wrap('<li>');
            } else {
                jsNavTwoLiObj.insertAfter($('.jsNavTwoLiDefault'));
            }
        }

        $(window).on('resize', function () {
            footerNavHack();
            footerNavTwoHack();
        });

        footerNavHack();
        footerNavTwoHack();
    },
    catalog() {},
    categories() {
        function mousHover() {
            $document.on('click', '.categories-item', function () {
                var menuBtn = $(this);

                if ($('.categories-item').hasClass('on')) {
                    $('.categories-item')
                        .removeClass('on')
                        .find('.categories-ul')
                        .removeClass('on');
                }

                $(this).addClass('on');
                menuBtn.find('.categories-ul').addClass('on');
            });
            $(document).mouseup(function (e) {
                // событие клика по веб-документу
                var div = $('.categories-item'); // тут указываем ID элемента
                if (
                    !div.is(e.target) && // если клик был не по нашему блоку
                    div.has(e.target).length === 0
                ) {
                    // и не по его дочерним элементам
                    $('.categories-item')
                        .removeClass('on')
                        .find('.categories-ul')
                        .removeClass('on');
                }
            });
        }

        if ($(window).width() > 960) {
            mousHover();
        }
    },
    related() {},
    product() {
        if ($(window).width() < 960) {
            $('.product-readmore').on('click', function () {
                $('.product-desc').slideToggle();
            });
        }

        $('.variations-btn .attached').on('click', function () {
            if ($(this).hasClass('active')) {
                return;
            }

            var el = $(this),
                name = el.text(),
                val = el.data('value'),
                parent = el.parents('.variations-btn').data('id');

            $('.variations-btn .attached').removeClass('active');
            el.addClass('active');

            $('#' + parent).val(val);
            $('#' + parent).change();
        });

        $('.single_variation_wrap').on(
            'show_variation',
            function (event, variation) {
                $('.product-price').html(variation.display_price + ' руб');
                console.log(variation);

                if (variation.image.url) {
                    let variationImage =
                        '<div class="swiper-slide">' +
                        '<a href="' +
                        variation.image.url +
                        '" data-fancybox="gallery"><figure class="product-slider__item">' +
                        '<img src="' +
                        variation.image.url +
                        '" loading="lazy"></figure></a>' +
                        '</div>';
                    // $('p.price').html(variation.price_html);
                    $('.product-slider-big .swiper-slide')
                        .first()
                        .html(variationImage);
                }
            }
        );

        var proQty = $('.pro-qty');
        proQty.append('<a href="#" class="inc qty-btn">+</a>');
        proQty.append('<a href="#" class= "dec qty-btn">-</a>');
        $('body').on('click', '.qty-btn', function (e) {
            e.preventDefault();
            var $button = $(this);
            var oldValue = $button.parent().find('input').val();
            const btnOrder = $('.btn-order');
            if (!oldValue) {
                oldValue = 1;
            }
            if ($button.hasClass('inc')) {
                var newVal = parseFloat(oldValue) + 1;
            } else {
                // Don't allow decrementing below zero
                if (oldValue > 1) {
                    var newVal = parseFloat(oldValue) - 1;
                } else {
                    newVal = 1;
                }
            }
            $button.parent().find('input').val(newVal).change();
            btnOrder.attr('data-quantity', newVal);
        });

        $(document).on('updated_cart_totals', function () {
            $('.pro-qty').append(
                '<a href="#" class="inc qty-btn">+</a><a href="#" class= "dec qty-btn">-</a>'
            );
        });
    },
    popup() {
        $('.initPopupCb').on('click', function () {
            $('.popup').show();
            $('.popup__overlay').show();
        });

        $('.popup__close').on('click', function () {
            $('.popup').hide();
            $('.popup__overlay').hide();
        });

        $('.popup__overlay').on('click', function () {
            $('.popup').hide();
            $('.popup__overlay').hide();
        });
    },
    accordion() {
        if ($(window).width() < 960) {
            $(document).on('click', '.section-category-item', function () {
                $(this).toggleClass('open').next().slideToggle();
                // $('.section-category-item')
                //     .not(this)
                //     .removeClass('open')
                //     .next()
                //     .slideUp();
            });
            $(document).on('click', '.section-category-nav__wrap', function () {
                $(this).toggleClass('open').next().slideToggle();
                // $('.section-category-nav__wrap')
                //     .not(this)
                //     .removeClass('open')
                //     .next()
                //     .slideUp();
            });
            $(document).on('click', '.section-category-title', function (e) {
                e.preventDefault();
            });
        }
    },
    slider() {
        const galleryThumbs = new Swiper('.product-slider-thumbs', {
            spaceBetween: 16,
            slidesPerView: 'auto',
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            autoHeight: true, //enable auto height
        });
        const galleryTop = new Swiper('.product-slider', {
            spaceBetween: 0,
            slidesPerView: 1,
            // navigation: {
            //     nextEl: '.swiper-btn-next',
            //     prevEl: '.swiper-btn-prev',
            // },
            thumbs: {
                swiper: galleryThumbs,
            },
        });

        var swiper = new Swiper('.section-slider-intro', {
            pagination: {
                el: '.section-slider__pagination',
            },
            autoplay: {
                delay: 3000,
            },
        });

        var swiper = new Swiper('.section-hits-slider', {
            slidesPerView: 4,
            spaceBetween: 30,
            observer: true,
            navigation: {
                nextEl: '.swiper-btn-next',
                prevEl: '.swiper-btn-prev',
            },
            pagination: {
                el: '.pagination-count',
                type: 'fraction',
            },
            breakpoints: {
                320: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                980: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
            },
        });

        var swiper = new Swiper('.products-mob-slider', {
            slidesPerView: 'auto',
            spaceBetween: 12,
            observer: true,
            freeMode: true,
        });
    },
    fancybox() {
        $('[data-fancybox]').fancybox({
            clickOutside: 'close',
            buttons: [
                //"zoom",
                //"share",
                //"slideShow",
                //"fullScreen",
                //"download",
                //"thumbs",
                'close',
            ],
            protect: true, // РїРѕР»СЊР·РѕРІР°С‚РµР»СЊ РЅРµ РјРѕР¶РµС‚ СЃРѕС…СЂР°РЅРёС‚СЊ РёР·РѕР±СЂР°Р¶РµРЅРёРµ
            // toolbar  : false // СѓР±СЂР°Р»Рё РїР°РЅРµР»СЊ РёРЅСЃС‚СЂСѓРјРµРЅС‚РѕРІ
            mobile: {
                clickContent: 'close',
                clickSlide: 'close',
            },
        });
    },
    woocommerce() {
        jQuery('div.woocommerce').on('click', '.qty-btn', function () {
            jQuery("[name='update_cart']").prop('disabled', false);
            // jQuery("[name='update_cart']").trigger('click');
            jQuery("[name='update_cart']").addClass('update');
        });
    },
    login() {
        if (localStorage.getItem('register') !== null) {
            const attention = localStorage.getItem('register');
            $('#login').removeClass('active');
            $('#register').addClass('active');
        } else {
            $('#login').addClass('active');
            $('#register').removeClass('active');
        }

        $('.login-link-reg').on('click', function () {
            $('#login').removeClass('active');
            $('#register').addClass('active');
            localStorage.setItem('register', 'yes');
        });

        $('.login-link-login').on('click', function () {
            $('#login').addClass('active');
            $('#register').removeClass('active');
            localStorage.removeItem('register');
            $('.woocommerce-notices-wrapper').html('');
        });

        $('.showlogin').on('click', function () {
            $('#login').addClass('active');
            $('#register').removeClass('active');
            localStorage.removeItem('register');
            $('.woocommerce-notices-wrapper').html('');
        });
    },
};

App.define = function (namespace) {
    var parts = namespace.split('.'),
        parent = App,
        i;

    //Убрать начальный префикс если это являетсся глобальной переменной
    if (parts[0] == 'App') {
        parts = parts.slice(1);
    }

    //Если в глобальном объекте нет свойства - создать его.
    for (var i = 0; i < parts.length; i++) {
        if (typeof parent[parts[i]] == 'undefined') {
            parent[parts[i]] = {};
        }
        parent = parent[parts[i]];
    }
    return parent;
};

/**

 * Components.js

 *

 * @author Dima Dodonov <dimadodonov@icloud.com>

 */



App.define('Components');



App.Components = (function () {

    function _init() {

        // console.log('Site Components Init');

        // App.Components.Input.init();

    }



    return {

        init: _init,

    };

})();



App.define('Components.Input');



App.Components.Input = (function () {

    function _init() {

        console.log('Site Components Input Init');

        _initMask();

    }



    function _initMask() {

        //Masked inputmask https://github.com/RobinHerbots/Inputmask

        let phoneMask = new Inputmask('+7 (999) 999-99-99');

        let inputPhone = $('.js-phone-mask');

        if (inputPhone) {

            phoneMask.mask(inputPhone);

        }

    }



    return {

        init: _init,

        initMask: _initMask,

    };

})();



$(function () {

    $('.header-search input').on('keyup', function () {

        var search = $(this).val();

        if (search.length < 3) {

            return false;

        }

        var data = {

            s: search,

            action: 'search_ajax',

            nonce: search_form.nonce,

        };

        $.ajax({

            url: search_form.ajaxurl,

            data: data,

            type: 'POST',

            dataType: 'json',

            beforeSend: function (xhr) {

                $('.header-search-input-loader').show();

                $('.icon--close').hide();

                $('.icon--search').hide();

                console.log('Ищем...');

            },

            success: function (data) {

                if (data.out) {

                    $('.header-search-result').show().html(data.out);

                    $('.overlay').addClass('on overlay-search');

                }

            },

            error: function () {

                console.log('error', arguments);

            },

            complete: function (xhr) {

                function hideLoader() {

                    $('.header-search-input-loader').hide();

                    $('.icon--close').show();

                }



                setTimeout(hideLoader, 500);

            },

        });

        console.log(search);

    });



    $('.icon--close').on('click', function () {

        $(this).hide();

        $('.header-search-result').hide();

        $('.header-search .icon--search').show();

        $('.header-search input').empty().val('');

        $('.overlay').removeClass('on');

    });



    $(document).on('mouseup', function (e) {

        // событие клика по веб-документу

        var div = $('.header-search'); // тут указываем ID элемента

        if (

            !div.is(e.target) && // если клик был не по нашему блоку

            div.has(e.target).length === 0

        ) {

            // и не по его дочерним элементам

            // $('.search_form_clear').hide();

            $('.header-search-result').hide();

            $('.search_form_field').empty();

            $('.overlay.overlay-search').removeClass('on overlay-search');

            // $('.searchform input[name="s"]').val('').change();

        }

    });

});





$(function () {
    App.init();
    svg4everybody();
});
